@import "react-calendar/dist/Calendar.css";

:root {
  --main-color: #30bb8a;
  --selected-bg-color: #30bb8a33;
  --focus-bg-color: #f8f8fa;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation button {
  color: var(--main-color);
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--focus-bg-color);
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__days__day--weekend {
  color: var(--main-color);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--focus-bg-color);
  color: var(--main-color);
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: var(--selected-bg-color);
  border-radius: 6px;
  font-weight: bold;
  color: var(--main-color);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--selected-bg-color);
  border-radius: 6px;
  font-weight: bold;
  color: var(--main-color);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--focus-bg-color);
}
.react-calendar__tile--active {
  background: var(--main-color);
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--main-color);
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--focus-bg-color);
}
.react-calendar__tile--range {
  background: var(--focus-bg-color);
  color: var(--main-color);
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: var(--main-color);
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: var(--main-color);
  color: white;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

abbr[title] {
  text-decoration: none !important;
}
